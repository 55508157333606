<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogCreateBMSUser">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Create User</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createUserDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4">
            <v-text-field
              dense
              outlined
              label="Email ID"
              class="field_height field_label_size FontSize"
              :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
              v-model="user_email_id"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius" outlined color="primary" @click="createUserDialogEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createUser } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogCreateBMSUser: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    user_type: "",
    userTypeItems: [
      { text: "Admin", value: "ADMIN" },
      { text: "Super Admin", value: "SUPER_ADMIN" },
    ],
    loading: false,
    user_email_id: "",
    SnackBarComponent: {},
  }),
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.createUserMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async createUserMethod() {
      var inputParams = {
        user_email_id: this.user_email_id,
      };
      console.log("inputParams", inputParams);
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(createUser, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateUser);
        console.log("Check Create User", ResultObject);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.createUserDialogEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log("List BMS User", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    createUserDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
