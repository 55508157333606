var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('CreateUserDialog',{attrs:{"dialogCreateBMSUser":_vm.dialogCreateBMSUser},on:{"clicked":_vm.createBMSUsersDialogEmit}}),_c('DeleteUserDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogDeleteUser":_vm.dialogDeleteUser},on:{"clicked":_vm.dialogDeleteUserEmit}}),_c('ActivateInactivateUserDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogActivateInactivateUser":_vm.dialogActivateInactivateUser},on:{"clicked":_vm.dialogActivateInactivateUserEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"heading1 PrimaryFontColor"},[_vm._v("Users")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-text-field',{staticClass:"field_height field_label_size FontSize maxWidthLarge mt-6 mr-2",attrs:{"dense":"","outlined":"","label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"borderRadius",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.dialogCreateBMSUser = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Create")],1)],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.userHeaders,"items":_vm.GetAllBMSUserList,"search":_vm.search},scopedSlots:_vm._u([{key:"item.sl_no",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(_vm.GetAllBMSUserList.indexOf(item) + 1)+" ")])]}},{key:"item.user_email_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.user_email_id)+" ")])]}},{key:"item.user_created_on",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.user_created_on ? new Date(item.user_created_on).toLocaleString() : "-")+" ")])]}},{key:"item.user_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.user_type.includes("_") ? item.user_type.replace("_", " ") : item.user_type)+" ")])]}},{key:"item.user_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize",class:item.user_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'},[_vm._v(" "+_vm._s(item.user_status)+" ")])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [(item.user_id != _vm.$store.getters.get_current_user_details.user_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":'red'},on:{"click":function($event){return _vm.checkItem(item, 'DELETE')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-delete')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(("Delete " + (item.user_email_id)))}})]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }