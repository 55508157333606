<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogDeleteUser" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div><span v-text="'Delete'"></span> User</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogDeleteUserEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-icon size="50px" color="red" v-text="'mdi-delete'"></v-icon>
          <div>Are you sure you want to delete {{ StoreObj.user_email_id }} ?</div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogDeleteUserEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="DeleteUserMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { deleteUser } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogDeleteUser: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    add_issuer: {
      user_name: "",
      user_email_id: "",
    },
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {},
  methods: {
    async DeleteUserMethod() {
      try {
        var inputParams = {
          user_email_id: this.StoreObj.user_email_id,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(deleteUser, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.DeleteUser);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogDeleteUserEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogDeleteUserEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
