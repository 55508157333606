<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateUserDialog :dialogCreateBMSUser="dialogCreateBMSUser" @clicked="createBMSUsersDialogEmit" />
    <DeleteUserDialog :StoreObj="StoreObj" :dialogDeleteUser="dialogDeleteUser" @clicked="dialogDeleteUserEmit" />
    <ActivateInactivateUserDialog :StoreObj="StoreObj" :dialogActivateInactivateUser="dialogActivateInactivateUser" @clicked="dialogActivateInactivateUserEmit" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Users</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field dense outlined class="field_height field_label_size FontSize maxWidthLarge mt-6 mr-2" v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>
            <v-btn small color="primary" @click="dialogCreateBMSUser = true" class="borderRadius"><v-icon small class="mr-1">mdi-plus</v-icon>Create</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table dense class="elevation-0" :headers="userHeaders" :items="GetAllBMSUserList" :search="search">
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllBMSUserList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.user_email_id`]="{ item }">
            <div class="FontSize">
              {{ item.user_email_id }}
            </div>
          </template>
          <template v-slot:[`item.user_created_on`]="{ item }">
            <div class="FontSize">
              {{ item.user_created_on ? new Date(item.user_created_on).toLocaleString() : "-" }}
            </div>
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            <div class="FontSize">
              {{ item.user_type.includes("_") ? item.user_type.replace("_", " ") : item.user_type }}
            </div>
          </template>
          <template v-slot:[`item.user_status`]="{ item }">
            <div class="FontSize" :class="item.user_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'">
              {{ item.user_status }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom v-if="item.user_id != $store.getters.get_current_user_details.user_id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click="checkItem(item, 'DELETE')"><v-icon small v-text="'mdi-delete'"></v-icon></v-btn>
              </template>
              <span v-text="`Delete ${item.user_email_id}`"></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import CreateUserDialog from "@/components/Users/Dialogs/CreateUserDialog.vue";
import DeleteUserDialog from "@/components/Users/Dialogs/DeleteUserDialog.vue";
import ActivateInactivateUserDialog from "@/components/Users/Dialogs/ActivateInactivateUserDialog.vue";
import { GetAllBMSUsers } from "@/mixins/GetAllBMSUsers.js";
export default {
  components: {
    CreateUserDialog,
    DeleteUserDialog,
    ActivateInactivateUserDialog,
    Overlay,
  },
  mixins: [GetAllBMSUsers],
  data: () => ({
    search:"",
    overlay: false,
    dialogCreateBMSUser: false,
    dialogActivateInactivateUser: false,
    GetAllBMSUserList: [],
    userHeaders: [
      {
        text: "Email ID",
        value: "user_email_id",
      },
      {
        text: "Created On",
        value: "user_created_on",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    StoreObj: {},
    dialogDeleteUser: false,
  }),
  mounted() {
    this.GetAllBMSUserMethod();
  },
  methods: {
    dialogDeleteUserEmit(Toggle) {
      this.dialogDeleteUser = false;
      if (Toggle == 2) {
        this.GetAllBMSUserMethod();
      }
    },
    dialogActivateInactivateUserEmit(Toggle) {
      this.dialogActivateInactivateUser = false;
      if (Toggle == 2) {
        this.GetAllBMSUserMethod();
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      if (action == "DELETE") {
        this.dialogDeleteUser = true;
      } else if (action == "ACTION") {
        this.dialogActivateInactivateUser = true;
      }
    },
    createBMSUsersDialogEmit(Toggle) {
      this.dialogCreateBMSUser = false;
      if (Toggle == 2) {
        this.GetAllBMSUserMethod();
      }
    },
  },
};
</script>
<style></style>
